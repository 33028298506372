import React from 'react'

import PropTypes from 'prop-types'

import './teamcard.css'

const Teamcard = (props) => {
  return (
    <div className={`teamcard-container ${props.rootClassName} `}>
      <img
        alt={props.imageAlt}
        src={props.imageSrc}
        className="teamcard-image"
      />
      <div className="teamcard-container1">
        <span className="teamcard-text">{props.name}</span>
        <span className="teamcard-text1">{props.description}</span>
      </div>
    </div>
  )
}

Teamcard.defaultProps = {
  name: 'name',
  imageSrc: '',
  rootClassName: '',
  imageAlt: 'image',
  description: '~',
}

Teamcard.propTypes = {
  name: PropTypes.string,
  imageSrc: PropTypes.string,
  rootClassName: PropTypes.string,
  imageAlt: PropTypes.string,
  description: PropTypes.string,
}

export default Teamcard
